/**
 * Get the plural form of a word based on a count
 */
function getLabelAndCount(singular: string, plural: string, count: number) {
	return count === 1 ? `${count} ${singular}` : `${count} ${plural}`;
}

/**
 * Capitalize first letter in a word
 */
function capitalize(word: string) {
	return word[0].toUpperCase() + word.slice(1);
}

/**
 * Equivalent to PHP's sleep method
 *
 * @usage
 * await sleep('2s');
 */
function sleep(time: string | number) {
	return new Promise((resolve) => wait(resolve, time)); // eslint-disable-line no-promise-executor-return
}

/**
 * Slightly better API around `setTimeout` including basic support for human readable strings
 *
 * @example
 * wait(() => console.log('Hello world!'), '1s');
 * // OR...
 * wait(() => console.log('Hello world!'), 1000);
 */
function wait(callback: (value: unknown) => void, time: string | number = 100) {
	let t;

	if (typeof time === 'string') {
		t = Number(time.slice(0, -1)) * 1000; // convert to seconds
	} else {
		t = time;
	}

	return setTimeout(callback, t);
}

interface CallBack<Params extends any[]> { (...args: Params): void }

/**
 * Call all functions
 *
 * See: https://malcolmkee.com/blog/typesafe-call-all/
 *
 * @usage
 * callAll(wait, sleep, capitalize);
 */
const callAll = <Params extends any[]>(
	...fns: Array<CallBack<Params> | undefined>
) => (...args: Params) => fns.forEach((fn) => typeof fn === 'function' && fn(...args));

/**
 * Wrap `JSON.stringify` to include formatting (line breaks, spacing) by default
 */
function prettyStringify(value: any) {
	return JSON.stringify(value, null, 4);
}

/**
 * Creates a proxy for `console` object (which is available in all contexts this code might be run) and namespaces it
 * as `report` while _also_ providing better serialization of complex objects. Keep in mind that we _could_ do any
 * special work in the body of the anonymous function returned but at the moment we are just serializing objects.
 */
const report = new Proxy(console, {
	get: (target, prop: keyof typeof console) => {
		// Only trap logging methods
		const loggingMethods = ['log', 'warn', 'error', 'info', 'debug'];

		if (loggingMethods.includes(prop) && typeof target[prop] === 'function') {
			return (...args: any[]) => {
				const formattedArgs = args.map(
					(arg) => (typeof arg === 'object' ? JSON.stringify(arg, null, 4) : arg)
				);

				return (target[prop] as Function).apply(target, formattedArgs);
			};
		}

		// Return original value for non-logging methods to maintain backwards compatibility with console
		return target[prop];
	},
}) as typeof console; // Cast the proxy as typeof console for proper type inference

const helpers = {
	callAll,
	getLabelAndCount,
	capitalize,
	report,
	prettyStringify,
	sleep,
	wait,
};

export {
	helpers as default,
	callAll,
	capitalize,
	getLabelAndCount,
	report,
	prettyStringify,
	sleep,
	wait,
};
